@font-face {
  font-family: "narkisim";
  src: url("./NarkissHadash-Extralight.woff2") format("woff2") ,
       url("./NarkissHadash-Extralight.woff") format("woff"),
       url("./NarkissHadash-Extralight.eot") format("embedded-opentype");
  font-weight: 200;
}

@font-face {
  font-family: "narkisim";
  src: url("./NarkissHadash-Light.woff2") format("woff2") ,
       url("./NarkissHadash-Light.woff") format("woff"),
       url("./NarkissHadash-Light.eot") format("embedded-opentype");
  font-weight: 300;
}

@font-face {
  font-family: "narkisim";
  src: url("./NarkissHadash-Medium.woff2") format("woff2") ,
       url("./NarkissHadash-Medium.woff") format("woff"),
       url("./NarkissHadash-Medium.eot") format("embedded-opentype");
  font-weight: 500;
}

@font-face {
  font-family: "narkisim";
  src: url("./NarkissHadash-Bold.woff2") format("woff2") ,
       url("./NarkissHadash-Bold.woff") format("woff"),
       url("./NarkissHadash-Bold.eot") format("embedded-opentype");
  font-weight: bold;
}

@font-face {
  font-family: "narkisim";
  src: url("./NarkissHadash-Bold.woff2") format("woff2") ,
       url("./NarkissHadash-Bold.woff") format("woff"),
       url("./NarkissHadash-Bold.eot") format("embedded-opentype");
  font-weight: 800;
}
