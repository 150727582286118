@import url("./assets/fonts/skoda/index.css");
@import url("./assets/fonts/vw/index.css");
@import url("./assets/fonts/seat/SEAT\ Bcn\ Hebrew/index.css");
@import url("./assets/fonts/seat/SEAT\ Bcn\ Hebrew\ Condensed/index.css");
@import url("./assets/fonts/cupra//index.css");
@import url("./assets/fonts/audi//index.css");

@font-face {
  font-family: "Almoni";
  src: url("./assets/fonts/almoni/almoni-neue-regular-aaa.woff2");
  font-weight: normal;
}

@font-face {
  font-family: "Almoni";
  src: url("./assets/fonts/almoni/almoni-neue-bold-aaa.woff2");
  font-weight: bold;
}

@font-face {
  font-family: "Almoni";
  src: url("./assets/fonts/almoni/almoni-neue-black-aaa.woff2");
  font-weight: 800;
}

body {
  font-family: "Almoni", "Heebo", Arial, "sans-serif";
  margin: 0;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* body[data-more-info="true"] #Configurator{
  z-index: 1000;
}
body[data-more-info="false"] #Configurator{
  z-index: 0;
} */

body * {
  box-sizing: border-box;
}

body *:focus {
  outline: none;
}

[class^="icon-"],
[class*=" icon-"] {
  display: flex;
  align-items: center;
  justify-content: center;
}

/**
 * overriding default combobox css styles
 */

#divPreContactUsButton {
  bottom: 70px !important;
}

#divWrappersupportedStreams_ifrContactUs.is-showing-options {
  z-index: 999 !important;
}

@media screen and (max-width: 768px){
  #divContactUsIframe {
    left: 0 !important;
  }

  #divPreContactUsButton {  
    bottom: 91.8% !important;
    right: 66% !important;
    left: auto !important;
  }
  #divWrappersupportedStreams_ifrContactUs.is-showing-options {
    z-index: 999 !important;
    left: 4.2em !important;
    top: 4.2em !important;
    flex-direction: column !important;
    
  }
  #btnImgPreButton_ifrContactUs {
    width: auto !important;
  }
}

#imgPreButton_ifrContactUs{
  max-width: 50px;
}