@font-face {
  font-family: "vw";
  src: url("./VWHebrew-Light.woff2") format("woff2") url("./VWHebrew-Light.otf")
      format("opentype"),
    url("./VWHebrew-Light.eot") format("embedded-opentype"),
    url("./VWHebrew-Light.ttf") format("truetype"),
    url("./VWHebrew-Light.woff") format("woff");
  font-weight: 200;
}

@font-face {
  font-family: "vw";
  src: url("./VWHebrew-Regular.woff2") format("woff2"),
    url("./VWHebrew-Regular.otf") format("opentype"),
    url("./VWHebrew-Regular.ttf") format("truetype"),
    url("./VWHebrew-Regular.eot") format("embedded-opentype"),
    url("./VWHebrew-Regular.woff") format("woff");
  font-weight: normal;
}

@font-face {
  font-family: "vw";
  src: url("./VWHebrew-Bold.woff2") format("woff2"),
    url("./VWHebrew-Bold.otf") format("opentype"),
    url("./VWHebrew-Bold.ttf") format("truetype"),
    url("./VWHebrew-Bold.eot") format("embedded-opentype"),
    url("./VWHebrew-Bold.woff") format("woff");
  font-weight: bold;
}

@font-face {
  font-family: "vw";
  src: url("./VWHebrew-Bold.woff2") format("woff2"),
    url("./VWHebrew-Bold.otf") format("opentype"),
    url("./VWHebrew-Bold.ttf") format("truetype"),
    url("./VWHebrew-Bold.eot") format("embedded-opentype"),
    url("./VWHebrew-Bold.woff") format("woff");
  font-weight: 800;
}
