@font-face {
  font-family: "audi";
  src: url("./AudiType/AudiType-Normal.woff") format("woff"),
    url("./AudiType/AudiType-Normal.woff2") format("woff2");
  unicode-range: U+0041-007A, U+0030-0039;
}

/* NarkissTam for Hebrew chars */

@font-face {
  font-family: "narkissTam";
  src: url("./NarkissTam/NarkissTam-Regular.woff") format("woff"),
    url("./NarkissTam/NarkissTam-Regular.woff2") format("woff2");
  font-weight: normal;
}

@font-face {
  font-family: "narkissTam";
  src: url("./NarkissTam/NarkissTam-Light.woff") format("woff"),
    url("./NarkissTam/NarkissTam-Light.woff2") format("woff2");
  font-weight: 200;
}

@font-face {
  font-family: "narkissTam";
  src: url("./NarkissTam/NarkissTam-Medium.woff") format("woff"),
    url("./NarkissTam/NarkissTam-Medium.woff2") format("woff2");
  font-weight: 300;
}

@font-face {
  font-family: "narkissTam";
  src: url("./NarkissTam/NarkissTam-Medium.woff") format("woff"),
    url("./NarkissTam/NarkissTam-Medium.woff2") format("woff2");
  font-weight: 500;
}

@font-face {
  font-family: "narkissTam";
  src: url("./NarkissTam/NarkissTam-Black.woff") format("woff"),
    url("./NarkissTam/NarkissTam-Black.woff2") format("woff2");
  font-weight: 700;
}

@font-face {
  font-family: "narkissTam";
  src: url("./NarkissTam/NarkissTam-Black.woff") format("woff"),
    url("./NarkissTam/NarkissTam-Black.woff2") format("woff2");
  font-weight: 800;
}

@font-face {
  font-family: "narkissTam";
  src: url("./NarkissTam/NarkissTam-Bold.woff") format("woff"),
    url("./NarkissTam/NarkissTam-Bold.woff2") format("woff2");
  font-weight: bold;
}

@font-face {
  font-family: "narkissTam";
  src: url("./NarkissTam/NarkissTam-Heavy.woff") format("woff"),
    url("./NarkissTam/NarkissTam-Heavy.woff2") format("woff2");
  font-weight: 800;
}
