@font-face {
  font-family: "seat bon hebrew";
  src: url("./webfonts/woff2/SeatBcn-Hebrew-UltraLight.woff2") format("woff2"),
    url("./print/SeatBcn-Hebrew-UltraLight.otf") format("opentype"),
    url("./webfonts/eot/SeatBcn-Hebrew-UltraLight.eot")
      format("embedded-opentype"),
    url("./webfonts/ttf/SeatBcn-Hebrew-UltraLight.ttf") format("truetype"),
    url("./webfonts/woff/SeatBcn-Hebrew-UltraLight.woff") format("woff");
  font-weight: 100;
}

@font-face {
  font-family: "seat bon hebrew";
  src: url("./webfonts/woff2/SeatBcn-Hebrew-Light.woff2") format("woff2"),
    url("./print/SeatBcn-Hebrew-Light.otf") format("opentype"),
    url("./webfonts/eot/SeatBcn-Hebrew-Light.eot") format("embedded-opentype"),
    url("./webfonts/ttf/SeatBcn-Hebrew-Light.ttf") format("truetype"),
    url("./webfonts/woff/SeatBcn-Hebrew-Light.woff") format("woff");
  font-weight: 200;
}

@font-face {
  font-family: "seat bon hebrew";
  src: url("./webfonts/woff2/SeatBcn-Hebrew-Medium.woff2") format("woff2"),
    url("./print/SeatBcn-Hebrew-Medium.otf") format("opentype"),
    url("./webfonts/eot/SeatBcn-Hebrew-Medium.eot") format("embedded-opentype"),
    url("./webfonts/ttf/SeatBcn-Hebrew-Medium.ttf") format("truetype"),
    url("./webfonts/woff/SeatBcn-Hebrew-Medium.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "seat bon hebrew";
  src: url("./webfonts/woff2/SeatBcn-Hebrew-Regular.woff2") format("woff2"),
    url("./print/SeatBcn-Hebrew-Regular.otf") format("opentype"),
    url("./webfonts/eot/SeatBcn-Hebrew-Regular.eot") format("embedded-opentype"),
    url("./webfonts/ttf/SeatBcn-Hebrew-Regular.ttf") format("truetype"),
    url("./webfonts/woff/SeatBcn-Hebrew-Regular.woff") format("woff");
  font-weight: normal;
}

@font-face {
  font-family: "seat bon hebrew";
  src: url("./webfonts/woff2/SeatBcn-Hebrew-Book.woff2") format("woff2"),
    url("./print/SeatBcn-Hebrew-Book.otf") format("opentype"),
    url("./webfonts/eot/SeatBcn-Hebrew-Book.eot") format("embedded-opentype"),
    url("./webfonts/ttf/SeatBcn-Hebrew-Book.ttf") format("truetype"),
    url("./webfonts/woff/SeatBcn-Hebrew-Book.woff") format("woff");
  font-weight: 600;
}

@font-face {
  font-family: "seat bon hebrew";
  src: url("./webfonts/woff2/SeatBcn-Hebrew-Bold.woff2") format("woff2"),
    url("./print/SeatBcn-Hebrew-Bold.otf") format("opentype"),
    url("./webfonts/eot/SeatBcn-Hebrew-Bold.eot") format("embedded-opentype"),
    url("./webfonts/ttf/SeatBcn-Hebrew-Bold.ttf") format("truetype"),
    url("./webfonts/woff/SeatBcn-Hebrew-Bold.woff") format("woff");
  font-weight: bold;
}

@font-face {
  font-family: "seat bon hebrew";
  src: url("./webfonts/woff2/SeatBcn-Hebrew-Black.woff2") format("woff2"),
    url("./print/SeatBcn-Hebrew-Black.otf") format("opentype"),
    url("./webfonts/eot/SeatBcn-Hebrew-Black.eot") format("embedded-opentype"),
    url("./webfonts/ttf/SeatBcn-Hebrew-Black.ttf") format("truetype"),
    url("./webfonts/woff/SeatBcn-Hebrew-Black.woff") format("woff");
  font-weight: 800;
}
