@font-face {
    font-family: "seat bon hebrew condensed";
    src: url("./webfonts/woff2/SeatBcn-Hebrew-UltraLightCondensed.woff2") format("woff2"),
      url("./print/SeatBcn-Hebrew-UltraLightCondensed.otf") format("opentype"),
      url("./webfonts/eot/SeatBcn-Hebrew-UltraLightCondensed.eot")
        format("embedded-opentype"),
      url("./webfonts/ttf/SeatBcn-Hebrew-UltraLightCondensed.ttf") format("truetype"),
      url("./webfonts/woff/SeatBcn-Hebrew-UltraLightCondensed.woff") format("woff");
    font-weight: 100;
  }
  
  @font-face {
    font-family: "seat bon hebrew condensed";
    src: url("./webfonts/woff2/SeatBcn-Hebrew-LightCondensed.woff2") format("woff2"),
      url("./print/SeatBcn-Hebrew-LightCondensed.otf") format("opentype"),
      url("./webfonts/eot/SeatBcn-Hebrew-LightCondensed.eot") format("embedded-opentype"),
      url("./webfonts/ttf/SeatBcn-Hebrew-LightCondensed.ttf") format("truetype"),
      url("./webfonts/woff/SeatBcn-Hebrew-LightCondensed.woff") format("woff");
    font-weight: 200;
  }
  
  @font-face {
    font-family: "seat bon hebrew condensed";
    src: url("./webfonts/woff2/SeatBcn-Hebrew-MediumCondensed.woff2") format("woff2"),
      url("./print/SeatBcn-Hebrew-MediumCondensed.otf") format("opentype"),
      url("./webfonts/eot/SeatBcn-Hebrew-MediumCondensed.eot") format("embedded-opentype"),
      url("./webfonts/ttf/SeatBcn-Hebrew-MediumCondensed.ttf") format("truetype"),
      url("./webfonts/woff/SeatBcn-Hebrew-MediumCondensed.woff") format("woff");
    font-weight: 500;
  }
  
  @font-face {
    font-family: "seat bon hebrew condensed";
    src: url("./webfonts/woff2/SeatBcn-Hebrew-Condensed.woff2") format("woff2"),
      url("./print/SeatBcn-Hebrew-Condensed.otf") format("opentype"),
      url("./webfonts/eot/SeatBcn-Hebrew-Condensed.eot") format("embedded-opentype"),
      url("./webfonts/ttf/SeatBcn-Hebrew-Condensed.ttf") format("truetype"),
      url("./webfonts/woff/SeatBcn-Hebrew-Condensed.woff") format("woff");
    font-weight: normal;
  }
  
  @font-face {
    font-family: "seat bon hebrew condensed";
    src: url("./webfonts/woff2/SeatBcn-Hebrew-BookCondensed.woff2") format("woff2"),
      url("./print/SeatBcn-Hebrew-BookCondensed.otf") format("opentype"),
      url("./webfonts/eot/SeatBcn-Hebrew-BookCondensed.eot") format("embedded-opentype"),
      url("./webfonts/ttf/SeatBcn-Hebrew-BookCondensed.ttf") format("truetype"),
      url("./webfonts/woff/SeatBcn-Hebrew-BookCondensed.woff") format("woff");
    font-weight: 600;
  }
  
  @font-face {
    font-family: "seat bon hebrew condensed";
    src: url("./webfonts/woff2/SeatBcn-Hebrew-BoldCondensed.woff2") format("woff2"),
      url("./print/SeatBcn-Hebrew-BoldCondensed.otf") format("opentype"),
      url("./webfonts/eot/SeatBcn-Hebrew-BoldCondensed.eot") format("embedded-opentype"),
      url("./webfonts/ttf/SeatBcn-Hebrew-BoldCondensed.ttf") format("truetype"),
      url("./webfonts/woff/SeatBcn-Hebrew-BoldCondensed.woff") format("woff");
    font-weight: bold;
  }
  
  @font-face {
    font-family: "seat bon hebrew";
    src: url("./webfonts/woff2/SeatBcn-Hebrew-BlackCondensed.woff2") format("woff2"),
      url("./print/SeatBcn-Hebrew-BlackCondensed.otf") format("opentype"),
      url("./webfonts/eot/SeatBcn-Hebrew-BlackCondensed.eot") format("embedded-opentype"),
      url("./webfonts/ttf/SeatBcn-Hebrew-BlackCondensed.ttf") format("truetype"),
      url("./webfonts/woff/SeatBcn-Hebrew-BlackCondensed.woff") format("woff");
    font-weight: 800;
  }
  