@font-face {
    font-family: "skoda";
    src: url("./almoni/almoni-dl-aaa-300.eot") format("embedded-opentype"),
        url("./almoni/almoni-dl-aaa-300.ttf") format("truetype"),
        url("./almoni/almoni-dl-aaa-300.woff") format("woff");
    font-weight: 300;
}

@font-face {
    font-family: "skoda";
    src: url("./almoni/almoni-dl-aaa-400.eot") format("embedded-opentype"),
        url("./almoni/almoni-dl-aaa-400.ttf") format("truetype"),
        url("./almoni/almoni-dl-aaa-400.woff") format("woff");
    font-weight: normal;
}

@font-face {
    font-family: "skoda";
    src: url("./almoni/almoni-dl-aaa-700.eot") format("embedded-opentype"),
        url("./almoni/almoni-dl-aaa-700.ttf") format("truetype"),
        url("./almoni/almoni-dl-aaa-700.woff") format("woff");
    font-weight: bold;
}

@font-face {
    font-family: "skoda";
    src: url("./almoni/almoni-dl-aaa-900.eot") format("embedded-opentype"),
        url("./almoni/almoni-dl-aaa-900.ttf") format("truetype"),
        url("./almoni/almoni-dl-aaa-900.woff") format("woff");
    font-weight: 900;
}


/* skodaNext for engilsh chars */

@font-face {
    font-family: 'skodaNext';
    src: url('./SKODANext/SKODANextArabic-Light.otf');
    font-weight: 200;
}

@font-face {
    font-family: 'skodaNext';
    src: url('./SKODANext/SKODANextArabic-Regular.otf');
    font-weight: normal;
}

@font-face {
    font-family: 'skodaNext';
    src: url('./SKODANext/SKODANextArabic-Bold.otf');
    font-weight: bold;
}

@font-face {
    font-family: 'skodaNext';
    src: url('./SKODANext/SKODANextArabic-Black.otf');
    font-weight: 800;
}